export default class Video {
  constructor() {
    $(".media__cover").on("click", (e) => {
      const target = $(e.currentTarget)
      this.playVideo(target)
    })

    const modal = $(".video-modal")

    modal
      .find(".video-modal__close")
      .on("click", () =>
        modal.fadeOut(300, () => modal.find(".video-modal__video").html("")),
      )

    const buttons = $("a.button")
    buttons.each((_, button) => {
      const href = button.href

      if (href.match("vimeo") || href.match("youtube") || href.match(".mp4")) {
        $(button).on("click", (e) => {
          e.preventDefault()

          let videoContainer = ""
          let videoId = ""

          const youtubeRegex =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/i
          const vimeoRegex =
            /^.*(?:vimeo\.com\/|player\.vimeo\.com\/)(?:video\/|(?:channels\/staffpicks\/|channels\/)|)((\w|-){7,10})/

          if (href.match("vimeo")) {
            videoId = href.match(vimeoRegex)[1]

            videoContainer = `
              <iframe width="960" height="540" src="//player.vimeo.com/video/${videoId}?autoplay=0&loop=0&title=0&byline=0&portrait=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
            `
          } else if (href.match("youtube")) {
            videoId = href.match(youtubeRegex)[2]

            videoContainer = `
              <iframe id="homepage_video" width="960" height="540" src="https://www.youtube.com/embed/${videoId}?autoplay=0&amp;mute=0&amp;showinfo=0&amp;controls=1&amp;rel=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
            `
          } else {
            videoContainer = `
              <video width="960" height="540" controls="" video="">
                <source src="${href}" type="video/mp4" />
              </video>
            `
          }

          modal.find(".video-modal__video").html(videoContainer)
          const iframe = modal.find("iframe")
          const vid = modal.find("video")

          if (iframe.length) {
            iframe[0].src += "&autoplay=1"
          } else {
            vid[0].play()
          }

          if (vid) modal.fadeIn(300).css("display", "flex")
        })
      }
    })
  }

  playVideo(target) {
    const video = target.siblings("media__video")
    const iframe = target.siblings("iframe")

    target.fadeOut(600)

    if (iframe.length) {
      iframe[0].src.replace("autoplay=0", "autoplay=1")
    } else {
      video[0].play()
    }
  }
}
